@use "./../variables/breakpoints" as break;

.fix-cta {
	--fix-cta-position-right: 1rem;

	position: fixed;
	bottom: 1rem;
	right: var(--fix-cta-position-right);

	z-index: 1049;

	.call-me-back,
	.cloud {
		font-size: 0.875rem;

		background-color: var(--white);

		border-radius: 1.5rem;
		border-bottom-right-radius: 0;

		width: max-content;

		border: 3px solid rgba(221, 226, 237, 1);
		box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);

		line-height: 1.2;

		position: absolute;
	}

	.call-me-back {
		padding: 2rem 1.5rem;

		bottom: calc(100% + 1rem);
		right: 50%;

		width: calc(100vw - calc(var(--fix-cta-position-right) * 2) - 50%);

		@media (min-width: break.$sm) {
			width: 25rem;
		}

		display: none;

		&.active {
			display: inline-block;
		}

		.form {
			&-select,
			&-control,
			&-check-input {
				background-color: #dde2ed;
				border: 0;
				box-shadow: unset;
			}

			&-check {
				display: flex;
				align-items: center;
				gap: 0.5rem;

				&-input {
					margin-block: auto;

					&:checked {
						background-color: #e63946;
					}
				}
			}

			&-control {
				&::placeholder {
					color: rgba(#303030, 0.5);
				}
			}

			&-select {
				&:first-child {
					color: rgba(#303030, 0.5);
				}
			}
		}

		&-title {
			font-family: "Montserrat", sans-serif;
		}

		&-input {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
		}
	}

	.cloud {
		
		display: none;

		padding: 1rem;

		top: calc(-100% - 2rem);
		right: 50%;

		&.active {
			display: inline-block;
		}
	}

	.phone{
		background-color: var(--main-color);
		border: 1px solid var(--white);

		border-radius: 50%;
		border-bottom-right-radius: 0;

		width: 3.75rem;
		height: 3.75rem;

		display: grid;
		place-items: center;

		cursor: pointer;

		img {
			width: 2rem;
			height: auto;
		}

		.btn{
			padding: 0;
		}
	}

	&-calendar{
		.call-me-back{
			display: none !important;
		}
	}
}
