@use "./../variables/breakpoints" as break;

.speciality {
	&-grid {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		@media (min-width: break.$md) {
			display: grid;
			gap: 0;
		}

		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: auto auto;
		grid-template-areas: "text text interpolation image";
	}

	// gap: 1rem;

	&-title {
		grid-column: text / interpolation;
		grid-row: 1;

		margin-top: 3rem;
	}

	&-interpolation {
		display: none;

		@media (min-width: break.$md) {
			display: grid;
		}

		grid-column: text / image;
		grid-row: 1 / -1;

		clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);

		.speciality {
			&-title {
				z-index: 1;
				position: relative;
			}
		}
	}

	&-image {
		grid-column: interpolation / image;
		grid-row: 1 / -1;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;

			@media (min-width: break.$md) {
				width: 110%;
			}
		}
	}

	&-text {
		grid-area: text;
		grid-row: 2;

		@media (min-width: break.$md) {
			margin-top: 6rem;
			padding-right: 3rem;
		}
	}
}
