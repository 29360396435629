.svg-animation {
	overflow: unset;
}

[data-aos="leaf-open left"] {
	.leaf-item-1,
	.leaf-item-2 {
		transform-origin: bottom left;
		transition: transform 1s ease;
	}

	.leaf-item-1 {
		transform: rotate(-40deg);
	}

	.leaf-item-2 {
		transform: rotate(-11deg);
	}

	&.aos-animate {
		.leaf-item-1 {
			transform: rotate(0);
		}

		.leaf-item-2 {
			transform: rotate(0);
		}
	}
}

[data-aos="leaf-open right"] {
	.leaf-item-1,
	.leaf-item-2 {
		transform-origin: top right;
		transition: transform 1s ease;
	}

	.leaf-item-1 {
		transform: rotate(-40deg);
	}

	.leaf-item-2 {
		transform: rotate(-11deg);
	}

	&.aos-animate {
		.leaf-item-1 {
			transform: rotate(0);

			// animation: wind 2s ease-in-out infinite alternate-reverse;
		}

		.leaf-item-2 {
			transform: rotate(0);
		}
	}
}

@keyframes wind {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(-5deg);
	}
}

.pulse:hover {
	span {
		animation: pulse 1s ease-in-out infinite alternate;
	}
}

@keyframes pulse {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(1.2);
	}
}

/* .sticky {
	top: 50% !important;
} */
