.client {
	&-logos {
		.swiper-slide {
			display: flex;
			align-items: center;
			justify-content: center;

			height: auto;

			filter: grayscale(100%);

			img {
				max-height: 6.25rem;
			}
		}
	}
}
