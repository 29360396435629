@use "./../variables/breakpoints" as break;

$hover: false;
$text-stroke: false;

.bar {
	overflow: hidden;

	position: relative;

	display: flex;
	align-items: center;

	--bar-timing: 15s;
	--bar-color: var(--main-color);
	--bar-text-color: var(--white);

	&-content {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;

		transition: background-color 0.2s ease-in-out;

		// background-color: var(--bar-color);

		@media (min-width: break.$lg) {
			background-color: transparent;

			--bar-text-color: var(--dark);
		}

		&:after {
			content: "";

			position: absolute;
			left: 50%;
			top: 50%;

			transform: translate(-50%, -50%);

			background-color: var(--bar-color);
			border-radius: 50%;
			aspect-ratio: 1;

			width: 0;
			height: auto;

			transition: width 0.4s ease-in-out;
			z-index: -2;
		}

		> * {
			display: flex;
			align-items: center;

			@if $text-stroke {
				color: transparent;
				-webkit-text-stroke: 1px var(--bar-text-color);
			}

			transition: color 0.2s ease-in-out;

			animation: bar-slide var(--bar-timing) linear infinite;

			padding-block: 0.2em;
			// padding-inline: 1rem;

			white-space: nowrap;

			position: relative;

			@if $text-stroke {
				&:nth-child(odd) {
					color: var(--bar-text-color);
				}
			}

			&:after {
				content: "";

				display: block;

				width: 1rem;
				height: 1rem;

				background-color: var(--white);
				margin-inline: 3.75rem;

				border-radius: 50%;
			}
		}
	}

	+ .bar {
		margin-top: -1px;
	}

	@if $hover {
		&:hover {
			.bar-content {
				> * {
					@media (min-width: break.$lg) {
						animation-play-state: paused;
					}

					-webkit-text-stroke-color: var(--white);

					&:nth-child(odd) {
						color: var(--white);
					}
				}

				&:after {
					width: 130vw;
				}
			}

			.bar-link {
				scale: 1;
			}
		}
	}

	&.reverse .bar-content > * {
		animation-direction: reverse;
	}

	&-link {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		transition: scale 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);

		scale: 1;

		@media (min-width: break.$lg) {
			scale: 0;
		}

		&-content {
			@if $hover {
				&:hover {
					color: var(--white);
				}
			}

			> span {
				display: none;
			}

			&:before {
				content: "";

				width: 30%;
				height: 100%;

				background: linear-gradient(90deg, rgba(213, 0, 88, 0) 0%, rgba(213, 0, 88, 1) 75%, rgba(213, 0, 88, 1) 100%);

				position: absolute;
				right: 0;
				top: 0;

				z-index: -1;
			}

			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;

			position: relative;

			gap: 1.6rem;

			width: 100%;
			height: 100%;

			padding: 1rem;

			@media (min-width: break.$lg) {
				padding: unset;

				position: absolute;
				right: unset;
				top: 50%;
				left: 50%;

				width: 20%;
				height: auto;

				transform: translate(-50%, -50%);
				aspect-ratio: 1;
				overflow: hidden;
				border-radius: 50%;

				color: var(--white);

				text-align: center;
				text-transform: uppercase;

				// background-color: var(--bar-color);
				border: 1px solid var(--white);

				align-items: center;

				&:before {
					content: unset;
				}

				> span {
					display: block;
				}
			}
		}
	}
}

@keyframes bar-slide {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-200%, 0);
	}
}

@keyframes bar-link-bounce {
	0% {
		scale: 0;
	}
	90% {
		scale: 1.1;
	}
	100% {
		scale: 1;
	}
}
