@use "./../variables/breakpoints" as break;

.text {
	&-primary {
		color: var(--main-color) !important;
	}

	&-grey {
		color: var(--grey);
	}

	&-black {
		color: var(--black);
	}

	&-genoa {
		color: var(--genoa-color) !important;
	}

	&-beryl {
		color: var(--beryl-color) !important;
	}
}

// text gradient
.text-gradient {
	&-1 {
		background: -webkit-linear-gradient(180deg, #e63946, #8292ac);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	&-2 {
		background: -webkit-linear-gradient(180deg, #ffffff, #3be63b);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	&-3 {
		background: -webkit-linear-gradient(180deg, #3be63b, #8292ac);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	&-4 {
		background: -webkit-linear-gradient(180deg, #212429, #8292ac);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	&-5 {
		background: -webkit-linear-gradient(180deg, #ffffff, #8292ac);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

// background colors
.bg {
	&-primary {
		background-color: var(--main-color) !important;
	}

	&-darken {
		background-color: var(--darken) !important;
	}

	&-beryl {
		background-color: var(--beryl-color) !important;
	}

	&-grey {
		background-color: var(--beryl-color) !important;
	}

	&-light-grey {
		background-color: var(--light-grey) !important;
	}

	@media (min-width: break.$md) {
		&-md {
			&-black {
				background: #000 !important;
			}
		}
	}

	&-gradient {
		&-hero {
			background-image: url(./../img/hero-bg.jpg);
			background-size: cover;
			background-position: top right;
		}

		&-1 {
			background: linear-gradient(131deg, rgba(139, 12, 71, 1) 0%, rgba(19, 3, 72, 1) 24%, rgba(2, 3, 31, 1) 65%, rgba(237, 33, 41, 1) 100%);
		}

		&-2 {
			background: linear-gradient(100deg, rgba(137, 58, 236, 1) 0%, rgba(0, 0, 0, 1) 36%, rgba(17, 27, 111, 1) 68%, rgba(15, 75, 99, 1) 100%);
		}

		&-3 {
			background: linear-gradient(150deg, rgba(42, 31, 125, 1) 0%, rgba(1, 1, 13, 1) 100%) !important;
		}

		&-4 {
			background-image: url(./../img/ricerca-gradient-bg.jpg);
			background-size: cover;
			background-position: bottom left;
		}

		&-5 {
			background: rgb(60, 8, 144) !important;
			background: linear-gradient(90deg, rgba(60, 8, 144, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 1) 100%) !important;
		}

		@media (min-width: break.$md) {
			&-md {
				&-black {
					background-color: var(--black) !important;
				}

				&-5 {
					background: rgb(60, 8, 144) !important;
					background: linear-gradient(90deg, rgba(60, 8, 144, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 1) 100%) !important;
				}
			}
		}
	}
}
