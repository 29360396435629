@use "./../variables/breakpoints" as break;

.mt {
	&-6 {
		margin-top: 4rem !important;
	}

	&-7 {
		margin-top: 5rem !important;
	}

	&-md {
		@media (min-width: break.$md) {
			&-7 {
				margin-top: 5rem !important;
			}
		}
	}
}
