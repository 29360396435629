// text transformation
.text-stroke {
	--stroke-width: 1px;

	color: transparent !important;
	-webkit-text-stroke: var(--stroke-width) var(--dark);
}

// font weight
.weight-300 {
	font-weight: 300;
}

// text underline position
.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6,
.hero-title {
	position: relative;
	z-index: 0;
}

.fancy-underline {
	position: relative;
	z-index: -1;

	&[data-aos="clip-path"] {
		&.aos-animate {
			&:after {
				clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
			}
		}
	}

	&:not([data-aos="clip-path"])::after {
		clip-path: unset;
	}

	&:after {
		content: "";

		clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
		transition: clip-path 1s linear;

		top: 50%;
		left: 50%;
		position: absolute;

		transform: translate(-50%, -45%) scale(1.3);
		width: 100%;
		height: 100%;

		background: {
			repeat: no-repeat;
			size: contain;
			position: center;
		}

		z-index: -1;
	}

	&.underline {
		&-sm {
			&:after {
				background-image: url("./../img/highlighted-sm.svg");
			}
		}

		&-md {
			&-1 {
				&:after {
					background-image: url("./../img/highlighted-md-1.svg");
					transform: translate(0%, -50%) scale(3);
				}
			}

			&-2 {
				&:after {
					background-image: url("./../img/highlighted-md-2.svg");
					transform: translate(50%, -50%) scale(4);
				}
			}

			&-3 {
				&:after {
					background-image: url("./../img/highlighted-md-3.svg");
					transform: translate(0%, -50%) scale(3);
				}
			}
		}

		&-lg {
			&:after {
				background-image: url("./../img/highlighted-lg.svg");
			}
		}

		&-xl {
			&:after {
				background-image: url("./../img/highlighted-xl.svg");
			}
		}
	}
}
