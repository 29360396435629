@use "./../variables/breakpoints" as break;

#ricerca {
	background: #000;

	@media (min-width: break.$lg) {
		background: linear-gradient(90deg, rgba(60, 8, 144, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 1) 100%);
	}

	overflow-x: hidden;

	@media (min-width: break.$lg) {
		overflow-x: unset;
	}

	.product {
		&-sticky {
			top: var(--section-py);

			&-container {
				padding-block-start: var(--section-py);

				@media (min-width: break.$md) {
					padding-block: var(--section-py);
				}

				@media (min-width: break.$lg) {
					background: transparent;
				}
			}
		}

		&-card-container {
			--grid-columns: 5;
			--grid-rows: 3;

			display: grid;
			grid-template: repeat(var(--grid-rows), 1fr) / repeat(var(--grid-columns), 1fr);

			.bag {
				img {
					width: 100%;
					height: 100%;

					object-fit: contain;
					aspect-ratio: 1;
				}

				&.back {
					grid-area: 2/2/-1/-1;

					filter: blur(4px);

					animation: float 9s ease-in-out infinite alternate;

					img {
						transform: rotate(310deg);
					}
				}

				&.front {
					grid-area: 1/1/-2/-2;

					animation: float 6s ease-in-out infinite;
				}
			}
		}
	}
}
