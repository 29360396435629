@use "./../variables/breakpoints" as break;

.hero {
	&-section {
		z-index: 1;
	}

	&-product-container {
		--grid-columns: 12;
		--grid-rows: 12;

		display: grid;
		grid-template: repeat(var(--grid-rows), 1fr) / repeat(var(--grid-columns), 1fr);

		aspect-ratio: 1;

		.product {
			img {
				max-width: 100%;
				height: auto;

				object-fit: contain;
			}

			&.back {
				// grid-area: 8/3/13/9;
				grid-area: 8/4/12/10;
				z-index: 1;
				animation: float 8s ease-in-out infinite;
			}

			&.middle {
				// grid-area: 3/2/12/12;
				grid-area: 1/3/12/13;
				z-index: 2;
				animation: float 12s ease-in-out infinite alternate;
			}

			&.front {
				//grid-area: 1/13/7/6;
				grid-area: 1/13/7/7;
				z-index: 3;
				animation: float 6s ease-in-out infinite;
			}
		}
	}

	&-title {
		font-size: 2.5rem;

		@media (min-width: 768px) {
			font-size: 4rem;
		}
	}
}

// test
@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-2rem);
	}
	100% {
		transform: translatey(0px);
	}
}
