@use "./../variables/font-weight" as fw;
@use "./../variables/breakpoints" as break;

.calendar {
	--calendar-font-color: var(--white);
	--weekdays-border-bottom-color: #404040;
	--calendar-date-hover-color: var(--main-color);
	--calendar-current-date-color: #1b1f21;
	--calendar-today-color: rgba(255, 255, 255, 0.2);
	--calendar-day-hover-color: rgba(255, 255, 255, 0.4);
	--calendar-today-innerborder-color: transparent;
	--calendar-nextprev-bg-color: transparent;
	--next-prev-arrow-color: var(--white);
	--calendar-border-radius: 1.5rem;
	--calendar-prevnext-date-color: rgba(255, 255, 255, 0.2);

	position: relative;

	color: var(--calendar-font-color);

	border-radius: var(--calendar-border-radius);

	box-shadow: 0px 2px 41px rgba(62, 69, 80, 0.252349);
	overflow: hidden;

	font-family: Montserrat, sans-serif;

	&-icon {
		aspect-ratio: 1;

		display: grid;
		place-items: center;

		img {
			width: 2.4375rem;
			height: 2.4375rem;
		}
	}

	&-inner {
		padding: 2.8rem 0.8rem;

		@media (min-width: break.$md) {
			padding: 2.8rem 1.8rem;
		}

		/* &.grid {
			display: grid;
			grid-template-rows: 1fr 500px;
		} */

		.calendar {
			&-body {
				/* display: grid;
				grid-template-columns: repeat(7, 1fr);
				text-align: center;

				text-transform: uppercase;

				font-size: 0.75rem; */

				.calendar-name,
				.calendar-days {
					display: grid;
					grid-template-columns: repeat(7, 1fr);
					text-align: center;

					text-transform: uppercase;

					font-size: 0.75rem;

					div {
						display: flex;
						justify-content: center;
						align-items: center;

						aspect-ratio: 1;

						border-radius: 50%;

						font-weight: fw.$medium;
					}
				}

				div {
					&.selected {
						> span {
							color: var(--dark) !important;
							background-color: var(--white) !important;
						}
					}

					> span {
						color: var(--calendar-font-color);
						text-decoration: none;

						aspect-ratio: 1;
						width: 100%;
						height: auto;

						display: flex;
						align-items: center;
						justify-content: center;

						// border: 1px solid var(--white);

						transition: 0.2s ease-in-out;
						transition-property: color, border-color, background-color;

						border-radius: 50%;

						font-weight: fw.$medium;
					}

					&.number-item {
						&:not(.weekend):not(.disabled) {
							&:hover span {
								background-color: var(--calendar-day-hover-color);
							}
						}

						&.weekend {
							opacity: 0.5;

							span {
								cursor: default;
							}
						}

						span[disabled] {
							cursor: not-allowed;
						}
					}

					&:not(:nth-child(-n + 7)):not(.prev-dates):not(.next-dates):hover
						span {
						border-color: var(--calendar-date-hover-color);
					}

					&.empty-dates:hover {
						border: 1px solid transparent;
					}
				}

				.calendar {
					&-today {
						span {
							background: var(--calendar-today-color);
						}
					}
				}

				.prev-dates,
				.next-dates {
					color: var(--calendar-prevnext-date-color);

					&:hover {
						border: 1px solid transparent;
						pointer-events: none;
					}
				}
			}

			&-controls {
				display: grid;
				grid-template-columns: repeat(3, 1fr);

				.calendar {
					&-year-month {
						display: flex;
						justify-content: space-evenly;
						align-items: center;
						gap: 0.5rem;

						min-width: 100px;

						.calendar {
							&-year-label,
							&-month-label {
								font-weight: 700;
								font-size: 1rem;
							}
						}
					}

					&-next {
						text-align: right;
					}

					&-prev {
						&.disabled {
							opacity: 0.5;
							pointer-events: none;

							span {
								cursor: not-allowed;
							}
						}
					}

					&-next,
					&-prev {
						span {
							color: var(--calendar-font-color);
							font-family: arial, consolas, sans-serif;

							text-decoration: none;

							display: inline-block;
							background: var(--calendar-nextprev-bg-color);

							svg {
								height: 20px;
								width: 20px;

								path {
									fill: var(--next-prev-arrow-color);
								}
							}
						}
					}
				}
			}

			/* &-today-date {
				display: grid;
				text-align: center;
				cursor: pointer;
				margin: 3px 0px;
				background: var(--calendar-current-date-color);
				padding: 8px 0px;
				border-radius: 10px;
				width: 80%;
				margin: auto;
			} */
		}
	}
}

.select-hour {
	display: flex;
	flex-direction: column;
	gap: 5px;

	// max-height: 400px;
	overflow-y: auto;

	padding-right: 6px;

	/* width */
	&::-webkit-scrollbar {
		width: 6px;
	}

	/* Track */
	/* &::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
 */
	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: var(--white);
		border-radius: 6px;
	}

	/* Handle on hover */
	/* &::-webkit-scrollbar-thumb:hover {
		background: #555;
	} */

	.form {
		&-check {
			padding: 0;
			margin-bottom: 0;
			min-height: unset;

			&-input {
				opacity: 0;

				// input checked
				&:checked ~ .form-check-label {
					color: var(--dark);
					background-color: var(--white);
				}

				&:disabled,
				&[disabled] {
					~ .form-check-label {
						opacity: 0.4;
						// pointer-events: none;
						background-color: transparent;
						color: #fff;
						cursor: not-allowed;
					}
				}
			}

			&-label {
				display: block;
				text-align: center;

				min-height: 3rem;

				display: flex;
				align-items: center;
				justify-content: center;

				border: 1px solid var(--white);
				border-radius: 5px;

				transition: all 0.2s ease-in-out;

				cursor: pointer;

				&:hover {
					background-color: var(--calendar-today-color);
				}
			}
		}
	}
}

.booking-wrapper {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;

	transition: height 0.5s ease-in-out;

	#calendar-input-wrapper,
	#input-wrapper {
		grid-area: 1 / 1;
		transition: all 0.5s ease-in-out;

		opacity: 1;

		transform: translateX(0);

		&:not(.active) {
			opacity: 0;
		}
	}

	#calendar-input-wrapper:not(.active) {
		transform: translateX(-100%);
	}

	#input-wrapper {
		> div {
			height: auto;

			@media (min-width: break.$md) {
				height: 100%;
			}
		}

		&:not(.active) {
			transform: translateX(100%);
		}
	}
}
