/* HEIGHT */
.height5vh {
	min-height: 5vh !important;
}
.height10vh {
	min-height: 10vh !important;
}
.height15vh {
	min-height: 15vh !important;
}
.height20vh {
	min-height: 20vh !important;
}
.height25vh {
	min-height: 25vh !important;
}
.height30vh {
	min-height: 30vh !important;
}
.height35vh {
	min-height: 35vh !important;
}
.height40vh {
	min-height: 40vh !important;
}
.height45vh {
	min-height: 45vh !important;
}
.height50vh {
	min-height: 50vh !important;
}
.height55vh {
	min-height: 55vh !important;
}
.height60vh {
	min-height: 60vh !important;
}
.height65vh {
	min-height: 65vh !important;
}
.height70vh {
	min-height: 70vh !important;
}
.height75vh {
	min-height: 75vh !important;
}
.height80vh {
	min-height: 80vh !important;
}
.height85vh {
	min-height: 85vh !important;
}
.height90vh {
	min-height: 90vh !important;
}
.height95vh {
	min-height: 95vh !important;
}
.height100vh {
	min-height: 100vh !important;
}
