@use "./../variables/font-weight" as fw;
@use "./../variables/breakpoints" as break;

html,
body {
	/* position: relative; */

	/* @media (min-width: break.$md) {
		overflow-x: clip;
	} */
}

body {
	color: var(--dark);
	background: var(--white);

	position: relative;

	font-family: "Overpass", sans-serif;

	margin: 0;
	padding: 0;
}

textarea {
	resize: none;

	&::placeholder {
		font-style: italic;
		font-weight: fw.$light;
		color: #00000040;
	}
}

p:last-of-type {
	margin-bottom: 0;
}

hr {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: #b4c0c5;
}

b,
strong {
	font-weight: fw.$bold;
}

video {
	max-width: 100%;
	height: auto;
	display: block;
}

small {
	font-size: 0.75rem;
	font-weight: fw.$light;
}

a {
	color: var(--main-color);
	font-weight: fw.$semi-bold;

	&:hover {
		color: var(--main-color);
		// text-decoration: none;
	}
}
