.c-hamburger {
	display: block;
	position: relative;
	overflow: hidden;
	margin: 0;
	padding: 0;
	width: 50px;
	height: 5rem;
	font-size: 0;
	text-indent: -9999px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	box-shadow: none;
	border-radius: none;
	border: none;
	cursor: pointer;
	transition: background 0.3s;

	&:focus {
		outline: none;
	}

	span {
		display: block;
		position: absolute;
		top: 23px;
		left: 5px;
		right: 5px;
		height: 2px;
		background: #fff;

		&:before,
		&:after {
			position: absolute;
			display: block;
			left: 0;
			width: 80%;
			height: 2px;
			background-color: #fff;
			content: "";
		}

		&:before {
			top: -10px;
			width: 60%;
		}

		&:after {
			bottom: -10px;
		}
	}

	&--htra {
		background: none;

		span {
			transition: transform 0.3s;

			&:before {
				transform-origin: top left;
				transition: transform 0.3s, width 0.3s, top 0.3s;
			}

			&:after {
				transform-origin: bottom left;
				transition: transform 0.3s, width 0.3s, bottom 0.3s;
			}
		}
	}
}
