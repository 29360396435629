@use "./../variables/font-weight" as fw;

.form {
	&-label {
		font-weight: fw.$extra-light;
	}

	&-control {
		// border: 2px solid #01533d;

		&:focus {
			border-color: var(--main-color);
			box-shadow: unset;
		}
	}
}
