@use "./../variables/font-weight" as fw;
@use "./../variables/breakpoints" as break;

$copyright-spacer: true;
$copyright-spacer-margin-block: 3rem;

.copyright {
	padding-block: 6rem;
	padding-block-end: 2.5rem;

	background-color: var(--dark);

	font-family: "Poppins", sans-serif;

	&-container {
		display: flex;
		align-items: center;
		gap: 1rem;

		flex-wrap: wrap;
		justify-content: space-between;

		font-size: 1.125rem;
	}

	@if $copyright-spacer {
		&-spacer {
			margin-block: $copyright-spacer-margin-block;
		}
	}

	&-nav {
		--copyright-nav-color: var(--white);

		padding: 0;
		margin: 0;
		list-style: none;

		display: flex;
		flex-wrap: wrap;

		justify-content: center;

		li {
			font-weight: fw.$light;

			&:not(:last-child)::after {
				content: "|";
				margin: 0 0.5rem;
			}
		}

		li,
		a {
			color: var(--copyright-nav-color);
			text-decoration: none;
		}
	}
}
