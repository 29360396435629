@use "./../variables/breakpoints" as break;

section {
	--section-py: 5rem;

	@media (min-width: break.$md) {
		--section-py: 9.375rem;
	}

	--section-p-end-last-type: 3.125rem;
	--section-margin: 0.5;
	position: relative;

	&.section-inner {
		padding-block: unset;

		> *:first-child {
			padding-block: var(--section-py);
		}
	}

	&.section-cut {
		@media (min-width: break.$xl) {
			.section-container {
				max-width: 95%;
			}
		}
	}

	&.section-lg-unset {
		@media (max-width: calc(break.$lg - 1px)) {
			.section-container {
				padding-block-start: 0;
			}
		}
	}

	&.section-sm {
		--section-py: 2.8rem;

		@media (min-width: break.$md) {
			--section-py: 3.8rem;
		}
	}

	&.section-md {
		--section-py: 5rem;

		@media (min-width: break.$md) {
			--section-py: 7.5rem;
		}
	}

	&.section-lg {
		--section-py: 8rem;

		@media (min-width: break.$md) {
			--section-py: 16rem;
		}

		@media (min-width: break.$lg) {
			--section-py: 12.5rem;
		}
	}
	/* 
	&:last-of-type {
		.section-container {
			padding-block-end: var(--section-p-end-last-type);
		}
	} */

	.section-box {
		--section-box-p-block: 3rem;
		--section-box-p-inline: 1.5rem;

		border-radius: 1.5rem;
		overflow: hidden;

		padding: var(--section-box-p-block) var(--section-box-p-inline);

		@media (min-width: break.$md) {
			--section-box-p-block: 3rem;
			--section-box-p-inline: 3rem;
		}

		@media (min-width: break.$lg) {
			--section-box-p-block: 4rem;
			--section-box-p-inline: 3rem;
		}

		@media (min-width: break.$xl) {
			--section-box-p-block: 6rem;
			--section-box-p-inline: 3.5rem;
		}

		@media (min-width: break.$xxl) {
			--section-box-p-block: 7.75rem;
			--section-box-p-inline: 6.5rem;
		}
	}

	.section-container {
		position: relative;

		// overflow-x: hidden;

		margin: auto;
		padding-block: calc(var(--section-py));

		.section-bg {
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			z-index: -1;

			img {
				width: 100%;
				height: 100%;

				object-fit: cover;
				object-position: center;
			}

			&.section-bg-filter {
				&:after {
					content: "";

					position: absolute;
					top: 0;
					left: 0;

					width: 100%;
					height: 100%;

					background-color: var(--main-color);

					z-index: 1;
				}

				&.multiply {
					img {
						filter: grayscale(1);
					}

					&:after {
						mix-blend-mode: multiply;
					}
				}
			}

			.section-bg-filter-hover {
				.section-bg-filter {
					img,
					&:after {
						transition: all 0.4s ease-in-out;
					}

					img {
						filter: grayscale(0);
					}

					&:after {
						opacity: 0;
					}
				}

				&:hover {
					.section-bg-filter {
						img {
							filter: grayscale(1);
						}

						&:after {
							opacity: 1;
						}
					}
				}
			}
		}

		> [class*="container"] + [class*="container"] {
			margin-top: 3rem;

			@media (min-width: break.$md) {
				margin-top: 4rem;
			}

			@media (min-width: break.$lg) {
				margin-top: 6rem;
			}

			@media (min-width: break.$xl) {
				margin-top: 7rem;
			}

			@media (min-width: break.$xxl) {
				margin-top: 9rem;
			}
		}
	}
}
