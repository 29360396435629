@use "./../variables/font-weight" as fw;
@use "./../variables/breakpoints" as break;

p {
	font-size: 1.125rem;
	font-weight: fw.$light;
}

/* heading */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.hero-title {
	position: relative;
	z-index: 0;
	font-family: "Montserrat", sans-serif;
}

h1,
.h1 {
	font-weight: fw.$semi-bold;
	font-size: 3rem;

	@media (min-width: break.$sm) {
		font-size: 4rem;
	}

	@media (min-width: break.$md) {
		font-size: 4.5rem;
	}

	@media (min-width: break.$xl) {
		font-size: 5.5rem;
	}

	@media (min-width: break.$xxl) {
		font-size: 5.9375rem;
	}
}

h2,
.h2 {
	font-weight: fw.$semi-bold;

	font-size: 2.5rem;

	@media (min-width: break.$md) {
		font-size: 4rem;
	}

	@media (min-width: break.$xl) {
		font-size: 4.5rem;
	}

	@media (min-width: break.$xxl) {
		font-size: 5rem;
	}
}

h3,
.h3 {
	font-weight: fw.$semi-bold;

	font-size: 2.2rem;

	@media (min-width: break.$md) {
		font-size: 3.5rem;
	}

	@media (min-width: break.$lg) {
		font-size: 3.75rem;
	}

	@media (min-width: break.$xl) {
		font-size: 4rem;
	}

	@media (min-width: break.$xxl) {
		font-size: 4.375rem;
	}
}

h4,
.h4 {
	font-weight: fw.$medium;

	font-size: 1.8rem;

	@media (min-width: break.$md) {
		font-size: 2.1rem;
	}

	@media (min-width: break.$lg) {
		font-size: 2.1rem;
	}

	@media (min-width: break.$xl) {
		font-size: 2.6rem;
	}

	@media (min-width: break.$xxl) {
		font-size: 3.125rem;
	}
}

h5,
.h5 {
	font-weight: fw.$medium;

	font-size: 1.6rem;

	@media (min-width: break.$md) {
		font-size: 1.8rem;
	}

	@media (min-width: break.$lg) {
		font-size: 2rem;
	}

	@media (min-width: break.$xl) {
		font-size: 2.2rem;
	}

	@media (min-width: break.$xxl) {
		font-size: 2.5rem;
	}
}

h6,
.h6 {
	font-weight: fw.$medium;

	font-size: 1.15rem;

	@media (min-width: break.$md) {
		font-size: 1.2rem;
	}

	@media (min-width: break.$lg) {
		font-size: 1.25rem;
	}

	@media (min-width: break.$xl) {
		font-size: 1.25rem;
	}

	@media (min-width: break.$xxl) {
		font-size: 1.375rem;
	}
}

// display
.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
	font-weight: fw.$semi-bold;
}

.display {
	&-1 {
		font-size: 2.4rem;
	}

	&-2 {
		font-size: 2.3rem;
		font-weight: fw.$bold;
	}

	&-3 {
		font-size: 2.2rem;
	}

	&-4 {
		font-size: 2.1rem;
	}

	&-5 {
		font-size: 2rem;
	}

	&-6 {
		font-size: 1.9rem;
	}
}

@media (min-width: break.$md) {
	.display {
		&-1 {
			font-size: 4.4rem;
		}
		&-2 {
			font-size: 3.8rem;
		}
		&-3 {
			font-size: 3.4rem;
		}
		&-4 {
			font-size: 3rem;
		}
		&-5 {
			font-size: 2.8rem;
		}
		&-6 {
			font-size: 2.4rem;
		}
	}
}

@media (min-width: break.$lg) {
	.display {
		&-1 {
			font-size: 4.8rem;
		}
		&-2 {
			font-size: 4.4rem;
		}
		&-3 {
			font-size: 4rem;
		}
		&-4 {
			font-size: 3.8rem;
		}
		&-5 {
			font-size: 3.4rem;
		}
		&-6 {
			font-size: 3rem;
		}
	}
}

@media (min-width: break.$xl) {
	.display {
		&-1 {
			font-size: 6.875rem;
		}

		&-2 {
			font-size: 3rem;
		}

		&-3 {
			font-size: 2.25rem;
		}

		&-4 {
			font-size: 1.75rem;
		}

		&-5 {
			font-size: 1.5rem;
		}

		&-6 {
			font-size: 1.125rem;
		}
	}
}

/* hero */
.hero-title {
	font-size: 2.6rem;

	line-height: 1;
	font-weight: fw.$semi-bold;

	@media (min-width: break.$md) {
		font-size: 4rem;
	}

	@media (min-width: break.$lg) {
		font-size: 4rem;
	}

	@media (min-width: break.$xl) {
		font-size: 4.5rem;
	}

	@media (min-width: break.$xxl) {
		font-size: 5.9375rem;
	}
}

.lead {
	font-size: 1.5rem;

	@media (min-width: break.$md) {
		font-size: 1.875rem;
	}
}
