.modal {
	--bs-modal-border-radius: 1.5rem;
	--bs-modal-inner-border-radius: 1.5rem;
	--bs-modal-header-border-color: transparent;
	--bs-modal-header-padding-x: 2rem;
	--bs-modal-header-padding-y: 1rem;

	&-header {
		padding-bottom: 0;
	}

	&.modal-clear{   
        .modal-header,
        .modal-body{
            border: 0 none;
            padding: 1.5rem;
        }
        .modal-header{
            .close{
                position: absolute;
                top: 30px;
                right: 30px;
                outline: none !important;
                width: 24px;
                height: 24px;
                padding: 0;
                span{
                    overflow: hidden;
                    text-indent: -9999px;
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTI0IDIuNDE3TDIxLjU4MyAwIDEyIDkuNTgzIDIuNDE3IDAgMCAyLjQxNyA5LjU4MyAxMiAwIDIxLjU4MyAyLjQxNyAyNCAxMiAxNC40MTcgMjEuNTgzIDI0IDI0IDIxLjU4MyAxNC40MTcgMTIgMjQgMi40MTd6IiBmaWxsPSIjZmZmIi8+PC9zdmc+);
                }
            }

            
        }
        .modal-content{
            background: none;
            border: 0 none;
            box-shadow: none;
            .close{
                color: #fff;
                opacity: 1;
            }
        }
    }


}
