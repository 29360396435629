@use "./../variables/breakpoints" as break;

#callModal,
#calendarModal{
	.modal{
		&-header{
			display: flex;
			flex-direction: column;
			gap: 1rem;
			padding: {
				right: 2rem;
				left: 2rem;
			};
			.btn-close{
				position: static;
			}
		}
		&-title{
			line-height: 1.5;
		}
		&-body{
			padding: {
				right: 2rem;
				left: 2rem;
			};
			.col-12{
				margin-top: 1rem;
			}
		}
	}
}

#callModal{
	.form {
		&-select,
		&-control,
		&-check-input {
			background-color: #dde2ed;
			border: 0;
			box-shadow: unset;
		}

		&-check {
			display: flex;
			align-items: center;
			gap: 0.5rem;

			&-input {
				margin-block: auto;

				&:checked {
					background-color: #e63946;
				}
			}
		}
	}
}

#calendarModal{
	.booking-wrapper,
	.select-hour {
		height: auto !important;
	}
}
