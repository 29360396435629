@use "./../variables/breakpoints" as break;

header {
	background-color: rgba(0, 0, 0, 0.25);

	transition: background-color 0.2s ease-in-out;

	> nav,
	> .mobile-nav {
		transition: transform 0.2s ease-in-out;
	}

	.logo {
		width: 10rem;
		transition: width 0.2s ease-in;

		@media (min-width: break.$sm) {
			width: 14rem;
		}

		@media (min-width: break.$md) {
			width: 19rem;
		}
	}

	&.scrolled {
		background-color: #000;

		.logo {
			width: 9rem;

			@media (min-width: break.$sm) {
				width: 12rem;
			}

			@media (min-width: break.$md) {
				width: 17rem;
			}
		}
	}

	.btn {
		@media (max-width: calc(break.$sm - 1px)) {
			.acosagile-arrow {
				display: none;
			}
		}

		@media (max-width: calc(break.$md - 1px)) {
			--bs-btn-padding-top: 0.5rem;
			--bs-btn-padding-right: 0.5rem;
			--bs-btn-padding-bottom: 0.5rem;
			--bs-btn-padding-left: 0.5rem;
		}
	}

	// header scrolled
}
