@use "./../variables/breakpoints" as break;

#solution {
	.custom-image {
		width: 100%;

		@media (min-width: break.$lg) {
			width: unset;
		}

		&-container {
			position: relative;
			margin-top: 2rem;
			height: 15rem;

			@media (min-width: break.$md) {
				height: 24rem;
			}

			@media (min-width: break.$lg) {
				height: unset;
				margin-top: unset;
			}
		}

		@media (min-width: break.$lg) {
			position: absolute;
			right: 0;
		}
	}
}
