@use "./../variables/breakpoints" as break;

.w-custom {
	--width: 100%;
	width: var(--width) !important;
}

.w {
	@media (min-width: break.$md) {
		&-md-max {
			width: max-content;
		}
	}

	@media (min-width: break.$lg) {
		&-lg-max {
			width: max-content;
		}
	}

	@media (min-width: break.$xl) {
		&-xl-max {
			width: max-content;
		}
	}
}

.min {
	@media (min-width: break.$lg) {
		&-lg {
			&-vh {
				&-100 {
					min-height: 100vh !important;
				}
			}
		}
	}
}
