@use "../variables/breakpoints" as break;

/* section - about us */
.about-us {
	&__title {
		position: relative;

		@media (min-width: break.$md) {
			margin-bottom: 6.25rem;
		}
	}

	.logo {
		--logo-height: 6.5rem;

		min-height: var(--logo-height);

		display: grid;
		place-items: center;
	}

	.arrow {
		display: none;

		@media (min-width: break.$md) {
			display: block;
		}

		position: absolute;
		top: 4rem;

		width: 5rem;
		height: auto;

		aspect-ratio: 1;

		&.left {
			left: 38%;
		}

		&.right {
			transform: scaleX(-1);
			right: 38%;
		}
	}
}

@keyframes canvas1 {
	from {
		rotate: 0deg;
	}

	to {
		rotate: 360deg;
	}
}

@keyframes canvas2 {
	from {
		transform: skew(-16deg, 14deg);
	}

	to {
		// transform: skew(16deg, -20deg);
		// transform: skew(46deg, -54deg);
		transform: skew(37deg, -24deg);
	}
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}
