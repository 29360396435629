@use "./../variables/breakpoints" as break;

$container-tight-max-width: 1050px;
$container-middle-max-width: 1129px;

.container {
	@media (min-width: break.$xxl) {
		max-width: 1336px;
	}

	&-tight {
		@media (min-width: break.$xxl) {
			max-width: $container-tight-max-width;
		}
	}

	&-middle {
		@media (min-width: break.$xxl) {
			max-width: $container-middle-max-width;
		}
	}
}
