@use "./../variables/breakpoints" as break;
.canvas {
	position: absolute;

	width: 100%;
	height: 100%;

	top: 0;
	left: 0;

	pointer-events: none;

	.canvas-item {
		position: absolute;

		--canvas-top: unset;
		--canvas-left: unset;
		--canvas-right: unset;
		--canvas-bottom: unset;
		--canvas-width: unset;
		--canvas-height: unset;

		--canvas-rotate: unset;

		top: var(--canvas-top);
		bottom: var(--canvas-bottom);
		left: var(--canvas-left);
		right: var(--canvas-right);

		width: var(--canvas-width);
		height: var(--canvas-height);

		img {
			transform: rotate(var(--canvas-rotate));
		}

		/* > div {
		} */
	}

	&.blur {
		> *:nth-child(1) {
			filter: blur(4px);
		}

		> *:nth-child(5) {
			filter: blur(2px);
		}
	}
}

#farsi-strada {
	.canvas-item {
		// circle green
		&.a {
			--canvas-top: -9rem;
			--canvas-right: 0;
			--canvas-width: 10rem;

			@media (min-width: break.$md) {
				--canvas-top: -9rem;
				--canvas-width: 18rem;
			}

			@media (min-width: break.$lg) {
				--canvas-top: -15rem;
				--canvas-width: 20rem;
			}
		}

		// circle yellow
		&.b {
			--canvas-bottom: 0;
			--canvas-left: -3rem;
			--canvas-width: 6rem;

			@media (min-width: break.$md) {
				--canvas-left: -6rem;
				--canvas-width: 15rem;
			}
		}
	}
}
