@use "./../variables/breakpoints" as break;

.col-fluid {
	@media (min-width: break.$lg) {
		position: absolute;
	}

	top: 0;
	height: 100%;

	padding: 0;

	&:first-child {
		left: 0;

		mask-image: linear-gradient(180deg, rgba(238, 238, 238, 1) 0%, rgba(216, 216, 216, 0.030704) 86%, rgba(216, 216, 216, 0) 100%);
		-webkit-mask-image: linear-gradient(180deg, rgba(238, 238, 238, 1) 0%, rgba(216, 216, 216, 0.030704) 86%, rgba(216, 216, 216, 0) 100%);

		@media (min-width: break.$lg) {
			mask-image: linear-gradient(90deg, rgba(238, 238, 238, 1) 0%, rgba(216, 216, 216, 0.030704) 86%, rgba(216, 216, 216, 0) 100%);
			-webkit-mask-image: linear-gradient(90deg, rgba(238, 238, 238, 1) 0%, rgba(216, 216, 216, 0.030704) 86%, rgba(216, 216, 216, 0) 100%);
		}

		+ * {
			margin-left: auto;
		}
	}

	&:last-child {
		right: 0;

		mask-image: linear-gradient(0, rgba(238, 238, 238, 1) 0%, rgba(216, 216, 216, 0.030704) 86%, rgba(216, 216, 216, 0) 100%);
		-webkit-mask-image: linear-gradient(0, rgba(238, 238, 238, 1) 0%, rgba(216, 216, 216, 0.030704) 86%, rgba(216, 216, 216, 0) 100%);

		@media (min-width: break.$lg) {
			mask-image: linear-gradient(270deg, rgba(238, 238, 238, 1) 0%, rgba(216, 216, 216, 0.030704) 86%, rgba(216, 216, 216, 0) 100%);
			-webkit-mask-image: linear-gradient(270deg, rgba(238, 238, 238, 1) 0%, rgba(216, 216, 216, 0.030704) 86%, rgba(216, 216, 216, 0) 100%);
		}

		+ * {
			margin-right: auto;
		}
	}
}
