@use "./../variables/breakpoints" as break;

.p {
	&-fixed {
		position: fixed !important;
	}

	@media (min-width: break.$lg) {
		&-lg {
			&-fixed {
				position: fixed !important;
			}
		}
	}
}
