@use "./../variables/breakpoints" as break;

.img {
	&-square {
		aspect-ratio: 1;

		width: 100%;
		height: auto;

		object-fit: cover;
	}

	&-full {
		width: 100%;
		height: 100%;

		object-fit: cover;
	}
}

.brand-logo {
	width: auto;
	height: 3.125rem;

	@media (min-width: break.$sm) {
		height: 4.375rem;
	}
}
