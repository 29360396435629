@use "./../variables/breakpoints" as break;

$custom-scrollbar: false;

:root {
	// colors
	// --main-color: #d50058;
	--dark: #212429;
	--darken: #191919;

	--light-grey: #f7f7f7;

	--grey: #646464;

	// food project
	--opacity: 1;

	--white-rgb: 255, 255, 255;
	--white: rgba(var(--white-rgb), var(--opacity));

	// #E63946
	--main-color-rgb: 230, 57, 70;
	--main-color: rgba(var(--main-color-rgb), var(--opacity));

	--secondary-color-rgb: 243, 125, 106; // #F37D6A
	--secondary-color: rgba(var(--secondary-color-rgb), var(--opacity));

	--pink-color-rgb: 246, 157, 181;
	--pink-color: rgba(var(--pink-color-rgb), var(--opacity));

	--aqua-color-rgb: 161, 218, 207;
	--aqua-color: rgba(var(--aqua-color-rgb), var(--opacity));

	--golden-color-rgb: 255, 206, 104;
	--golden-color: rgba(var(--golden-color-rgb), var(--opacity));

	--genoa-color-rgb: 19, 121, 116;
	--genoa-color: rgba(var(--genoa-color-rgb), var(--opacity));

	--beryl-color-rgb: 207, 229, 183;
	--beryl-color: rgba(var(--beryl-color-rgb), var(--opacity));

	--main-color-dark: #8d1e26;

	--froly-color: #f37d6a;
}

// scrollbar
@if ($custom-scrollbar) {
	*::-webkit-scrollbar {
		width: 6px;
		background: none;
	}
	*::-webkit-scrollbar-track {
		background-color: #f3f4f7;
		margin: 0;
	}
	*::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.25);
		border-radius: 5px;
	}
	*::-webkit-scrollbar-thumb:hover {
		background-color: var(--main-color);
	}
}
