@use "./../variables/font-weight" as fw;
@use "./../variables/breakpoints" as break;

.form {
	--form-absolute-el-x-axis: 1.375rem;
	--form-absolute-el-y-axis: 1.25rem;

	position: relative;
	border-radius: 1.5rem;
	z-index: 0;

	background-color: var(--beryl-color);
	padding: 4rem 1rem 4rem;

	@media (min-width: break.$md) {
		padding: 5rem 3rem;
	}

	@media (min-width: break.$lg) {
		padding: 4rem 6rem;
	}

	&-title {
		font-size: 1.5rem;
		font-weight: fw.$medium;
	}

	.leaf {
		position: absolute;
		top: -3rem;
		right: 0;

		width: 8rem;

		@media (min-width: break.$lg) {
			width: 13rem;
		}
	}

	&:after {
		content: "";
	}

	small {
		position: absolute;

		bottom: var(--form-absolute-el-y-axis);
		left: var(--form-absolute-el-x-axis);
	}

	// modal close btn
	[data-bs-dismiss="modal"] {
		font-size: 2rem;

		position: absolute;
		top: var(--form-absolute-el-y-axis);
		right: var(--form-absolute-el-x-axis);
	}

	textarea {
		height: 6.25rem;
	}
}
