@use "./../variables/breakpoints" as break;
@use "./../variables/font-weight" as fw;

$btn-icon-hover-rotate: false;

.btn {
	display: inline-flex;
	align-items: center;
	gap: 1rem;

	text-align: left;

	--bs-btn-font-family: "Overpass", sans-serif;
	--bs-btn-font-size: 0.875rem;

	--bs-btn-border-radius: 0;
	--bs-btn-box-shadow: unset;
	--bs-btn-focus-box-shadow: unset;

	--bs-btn-padding-top: 0.9375rem;
	--bs-btn-padding-right: 1.4375rem;
	--bs-btn-padding-bottom: 0.875rem;
	--bs-btn-padding-left: 1.5625rem;

	--bs-btn-border-width: 0.125rem;

	--bs-btn-font-weight: #{fw.$semi-bold};

	--bs-btn-active-shadow: unset;

	--bs-btn-border-radius: 0.25rem;

	padding: var(--bs-btn-padding-top) var(--bs-btn-padding-right) var(--bs-btn-padding-bottom) var(--bs-btn-padding-left);

	&:not(.btn-action) {
		> [class|="material"],
		> [class|="icon"] {
			margin-left: -0.4rem;
			margin-right: -0.4rem;
			line-height: 0;
		}

		// ecosagile
		> .acosagile-arrow {
			font-size: 0.7rem;
		}
	}

	// hover rotate
	@if $btn-icon-hover-rotate {
		> * {
			transition: rotate 0.2s ease;
		}

		&:hover {
			> [class|="material"] {
				rotate: 180deg;
			}
		}
	}

	&-primary {
		--bs-btn-color: var(--white);
		--bs-btn-bg: var(--main-color);
		--bs-btn-border-color: var(--main-color);

		--bs-btn-hover-color: var(--main-color);
		--bs-btn-hover-bg: var(--white);
		--bs-btn-hover-border-color: var(--main-color);

		--bs-btn-active-color: var(--white);
		--bs-btn-active-bg: var(--main-color-dark);
		--bs-btn-active-border-color: var(--main-color-dark);

		--bs-btn-disabled-color: var(--white);
		--bs-btn-disabled-bg: var(--main-color);
		--bs-btn-disabled-border-color: var(--main-color);
	}

	&-outline-primary {
		--bs-btn-color: var(--main-color);
		--bs-btn-bg: transparent;

		--bs-btn-border-color: var(--main-color);

		// hover
		--bs-btn-hover-color: var(--white);
		--bs-btn-hover-bg: var(--main-color);
		--bs-btn-hover-border-color: var(--main-color);

		--bs-btn-active-color: var(--white);
		--bs-btn-active-bg: var(--main-color-dark);
		--bs-btn-active-border-color: var(--main-color-dark);
	}

	&-secondary {
		--bs-btn-color: var(--white);
		--bs-btn-bg: var(--secondary-color);
		--bs-btn-border-color: var(--secondary-color);

		--bs-btn-hover-color: var(--secondary-color);
		--bs-btn-hover-bg: var(--white);
		--bs-btn-hover-border-color: var(--secondary-color);
	}

	&-outline-secondary {
		--bs-btn-color: var(--secondary-color);
		--bs-btn-bg: rgba(var(--secondary-color-rgb), var(--opacity));
		--opacity: 0.12;
		--bs-btn-border-color: var(--secondary-color);

		--bs-btn-hover-color: var(--white);
		--bs-btn-hover-bg: var(--secondary-color);
		--bs-btn-hover-border-color: var(--secondary-color);
	}

	&-white {
		--bs-btn-color: var(--dark);
		--bs-btn-bg: var(--white);
		--bs-btn-border-color: var(--white);
		--bs-btn-hover-color: var(--white);
		--bs-btn-hover-bg: transparent;
		--bs-btn-hover-border-color: var(--white);

		--bs-btn-active-color: var(--dark);
		--bs-btn-active-bg: var(--white);
		--bs-btn-active-border-color: var(--white);

		--bs-btn-disabled-color: var(--dark);
		--bs-btn-disabled-bg: var(--white);
		--bs-btn-disabled-border-color: var(--white);

		&-opacity {
			background-color: rgba(#fff, 30%);
		}
	}

	&-outline-white {
		--bs-btn-color: var(--white);
		--bs-btn-bg: transparent;
		--bs-btn-border-color: var(--white);

		--bs-btn-hover-color: var(--main-color);
		--bs-btn-hover-bg: var(--white);
		--bs-btn-hover-border-color: var(--white);

		--bs-btn-active-color: var(--dark);
		--bs-btn-active-bg: var(--white);
		--bs-btn-active-border-color: var(--white);

		--bs-btn-disabled-color: var(--dark);
		--bs-btn-disabled-bg: var(--white);
		--bs-btn-disabled-border-color: var(--white);
	}

	&-action {
		justify-content: center;

		padding: 0.6rem;

		aspect-ratio: 1;
		height: auto;

		border-radius: 9999px;
	}
}
