$icomoon-font-family: "ecosagile" !default;
$icomoon-font-path: "./../fonts" !default;

$acosagile-vimeo: "\e906";
$acosagile-arrow: "\e900";
$acosagile-facebook: "\e901";
$acosagile-instagram: "\e902";
$acosagile-linkedin: "\e903";
$acosagile-twitter: "\e904";
$acosagile-youtube: "\e905";

@font-face {
	font-family: "#{$icomoon-font-family}";
	src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?pwcd31");
	src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?pwcd31#iefix") format("embedded-opentype"), url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?pwcd31") format("truetype"), url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?pwcd31") format("woff"), url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?pwcd31##{$icomoon-font-family}") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="acosagile-"],
[class*=" acosagile-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "#{$icomoon-font-family}" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.acosagile-vimeo {
	&:before {
		content: $acosagile-vimeo;
	}
}
.acosagile-arrow {
	&:before {
		content: $acosagile-arrow;
	}
}
.acosagile-facebook {
	&:before {
		content: $acosagile-facebook;
	}
}
.acosagile-instagram {
	&:before {
		content: $acosagile-instagram;
	}
}
.acosagile-linkedin {
	&:before {
		content: $acosagile-linkedin;
	}
}
.acosagile-twitter {
	&:before {
		content: $acosagile-twitter;
	}
}
.acosagile-youtube {
	&:before {
		content: $acosagile-youtube;
	}
}
